import { Button } from 'rsuite'
import { MouseEvent, SyntheticEvent, useState } from 'react'
import dynamic from 'next/dynamic'

import { Product } from '../../utils/types/Product'
import SaveProductButton from '../MyProducts/SaveProductButton'
import { AnalyticsContext } from '../../utils/types/analytics'
import CartIcon from '../Icons/CartIcon'
import useTrackingContext from '../../services/trackingContext/useTrackingContext'
import useGetAnalyticsLocation from '../../services/analytics/useGetAnalyticsLocation'

import styles from '../../styles/ProductCardButtons.module.less'

const AddToCartModal = dynamic(() => import('../Modals/AddToCartModal'))

interface ProductCardButtonsProps {
  product: Product
  analyticsContext: AnalyticsContext
  className?: string
  onClick?: (event?: MouseEvent<HTMLElement>) => void
}

const ProductCardButtons = (props: ProductCardButtonsProps) => {
  const { product, analyticsContext, className, onClick } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const analyticsLocation = useGetAnalyticsLocation('product_card_simple')

  const { dispatchTrackingContext } = useTrackingContext()

  const handleCloseCartModal = (event?: SyntheticEvent) => {
    event?.stopPropagation()
    event?.preventDefault()
    setIsModalOpen(false)
  }

  const handleCartIconClick = (event?: MouseEvent<HTMLElement>) => {
    event?.stopPropagation()
    event?.preventDefault()

    // Set add to cart analytics context to global state
    dispatchTrackingContext({
      eventName: 'add_to_cart',
      eventParameters: {
        context: analyticsContext,
      },
    })

    setIsModalOpen(true)
    onClick?.(event)
  }

  const handleHeartIconClick = (event?: MouseEvent<HTMLElement>) => {
    event?.stopPropagation()
    event?.preventDefault()
    onClick?.(event)
  }

  return (
    <div
      onClick={(event) => {
        event?.stopPropagation()
        event?.preventDefault()
      }}
      role="button"
      tabIndex={0}
      onKeyDown={() => {}}
      className={`${styles['product-card-buttons']} ${className}`}
    >
      <SaveProductButton
        analyticsLocation={analyticsContext || analyticsLocation}
        product={product}
        className={styles['save-product-button']}
        appearance="ghost"
        iconClassName={styles['heart-icon']}
        onClick={handleHeartIconClick}
      />
      <Button
        appearance="primary"
        className={styles['add-to-cart-button']}
        onClick={handleCartIconClick}
      >
        <CartIcon />
      </Button>

      {isModalOpen && (
        <AddToCartModal
          open={isModalOpen}
          onClose={handleCloseCartModal}
          product={product}
        />
      )}
    </div>
  )
}

export default ProductCardButtons
