import { NextApiRequest } from 'next'

import { ProductBaseIncludes, ProductListFieldsQuery } from './constants'

/**
 * Builds a query string for fetching products by their product codes
 *
 * @param productCodes - Array of product codes to filter by
 * @param pageSize - Number of products to return per page
 * @returns A query string or null if no product codes are provided
 */
export const buildProductCodesQuery = (
  productCodes: string[],
  pageSize: number = 100,
): string | null => {
  if (!productCodes.length) return null

  const baseQuery = `include=${ProductBaseIncludes.join(',')}${ProductListFieldsQuery}`
  let filterParam = ''

  if (productCodes.length === 1) {
    filterParam = `&filter[%3D%3D][product.code]=${productCodes[0]}`
  } else {
    const codeFilters = productCodes.map((code) => `filter[%7C%7C][][%3D%3D][product.code]=${code}`).join('&')
    filterParam = `&${codeFilters}`
  }

  return `${baseQuery}&page[limit]=${pageSize}${filterParam}`
}

const checkBasicAuth = (req: NextApiRequest): boolean => {
  const [AUTH_USER, AUTH_PASS] = (process.env.NEXTJS_API_BASIC_AUTH || ':').split(':')
  const authheader = req.headers.authorization || req.headers.Authorization

  // Auth header is an array, which is not supported by atob
  if (Array.isArray(authheader)) {
    return false
  }

  const [authUser = '', pass = ''] = atob(authheader?.split(' ')?.[1] || '').split(':')

  return authUser === AUTH_USER && pass === AUTH_PASS
}

export default checkBasicAuth
