import { Placeholder } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import ProductCardSimple from '../SingleProduct/ProductCardSimple'
import ProductCardNarrow from '../SingleProduct/ProductCardNarrow'
import { Product } from '../../utils/types/Product'
import Carousel from '../Layouts/Carousel'
import ArrowLink from '../ArrowLink'
import useUrls from '../../services/useUrls'
import useGetAnalyticsLocation from '../../services/analytics/useGetAnalyticsLocation'
import { ProductCarouselTypeValues } from '../../utils/types/analytics'
import useIsOnMobile from '../../services/useIsOnMobile'
import { ScreenSize } from '../../utils/constants'

import styles from '../../styles/ProductCarousel.module.less'

export type ProductCardType = 'simple' | 'narrow'

interface ProductCarouselProps {
  className?: string
  carouselType: ProductCarouselTypeValues // Mandatory for analytics context
  title?: string
  titleSpacerClassName?: string
  products?: Product[]
  isLoading?: boolean
  browseLinkText?: string
  hasBrowseLink?: boolean
  isGrid?: boolean
  productCardType: ProductCardType
  align?: 'left' | 'center'
  hasCardBorder?: boolean
  carouselGap?: number
  hideArrows?: boolean
  hide?: boolean
  browseLinkHref?: string
  isSupplierNameVisible?: boolean
}

const DEFAULT_SKELETON_CARD_AMOUNT = 10

const ProductCarousel = (props: ProductCarouselProps) => {
  const {
    className = '',
    carouselType,
    products,
    title,
    titleSpacerClassName = '',
    isLoading = false,
    hasBrowseLink = false,
    browseLinkText,
    isGrid = false,
    productCardType = 'simple',
    align = 'center',
    hasCardBorder = false,
    carouselGap,
    hideArrows = false,
    hide = false,
    browseLinkHref = '/products',
    isSupplierNameVisible = true,
  } = props

  const { urlT } = useUrls()
  const { t } = useTranslation('products')
  const isOnMobile = useIsOnMobile()
  const isOnTablet = useIsOnMobile(ScreenSize.lg)

  const browseLinkLabel = browseLinkText || t('common:Browse products') || ''

  const isValidProducts = products && products.length > 0 && products.every(
    (product) => product.media,
  )

  const browseLinkUrl = urlT(browseLinkHref)
  const analyticsContext = useGetAnalyticsLocation(carouselType)

  const getCardHeight = () => {
    if (productCardType === 'simple') {
      return '280px'
    }

    if (!isSupplierNameVisible) {
      return '325px'
    }

    // Regular narrow card height
    return '408px'
  }

  const renderProductCards = (placeholderCount: number) => ((isLoading || !isValidProducts)
    ? (
      Array.from({ length: placeholderCount }, (_, i) => (
        <Placeholder.Graph
          active
          key={i}
          style={{
            height: getCardHeight(), // Set height of skeleton cards dynamically
          }}
          className={`${styles['skeleton-loading-card-carousel']} ${isGrid ? styles['skeleton-loading-card-grid'] : ''}`}
        />
      ))
    )
    : (
      <>
        {products?.map((product) => (
          productCardType === 'simple' && !isOnTablet // Only show narrow cards on touch screen devices
            ? (
              <ProductCardSimple
                key={product['product.code']}
                product={product}
                align={align}
                hasBorder={hasCardBorder}
                analyticsContext={analyticsContext}
                isGrid={isGrid}
              />
            ) : (
              <ProductCardNarrow
                key={product['product.code']}
                product={product}
                hasBorder={hasCardBorder}
                analyticsContext={analyticsContext}
                isGrid={isGrid}
                isSupplierNameVisible={isSupplierNameVisible}
              />
            )
        ))}
      </>
    ))

  const productCardTypeClass = productCardType === 'simple' ? styles.simple : styles.narrow

  if (hide) {
    return null
  }

  return (
    <div className={`${productCardTypeClass} ${className}`}>
      {/* Title is optional for narrow productCardType, required for simple */}
      {(title || productCardType === 'simple') && (
        <h4 className={`${titleSpacerClassName || 'margin-bottom-spacer-double'}`}>
          {title}
        </h4>
      )}

      {hasBrowseLink && !isOnMobile && (
        <ArrowLink
          className={styles['browse-link']}
          href={browseLinkUrl}
          text={browseLinkLabel}
          bold
        />
      )}

      {isGrid ? (
        <div className={styles['product-grid']}>
          {renderProductCards(products?.length || DEFAULT_SKELETON_CARD_AMOUNT)}
        </div>
      ) : (
        <Carousel
          gradientOffset={0}
          hideArrows={hideArrows}
          hideArrowsFade={hideArrows}
          gap={carouselGap ?? (productCardType === 'narrow' ? 15 : 10)}
        >
          {renderProductCards(DEFAULT_SKELETON_CARD_AMOUNT)}
        </Carousel>
      )}
    </div>
  )
}

export default ProductCarousel
