import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { FlexboxGrid } from 'rsuite'
import Link from 'next/link'
import { MouseEvent } from 'react'
import { useRouter } from 'next/router'

import { getPricePerPieceUnit, getProductSalesCount, getSmallestBarcodeItemsMoq } from '../../lib/salesUnit'
import { getProductImageAltText, getProductName } from '../../lib/text'
import { Product } from '../../utils/types/Product'
import { formatPriceNumber } from '../../utils/math'
import usePrice from '../../services/usePrice'
import { getLargePreview, imageLoader } from '../../utils/images'
import ProductEmptyImage from './ProductEmptyImage'
import { getPathName } from '../../lib/product'
import useUrls from '../../services/useUrls'
import useGetAnalyticsLocation from '../../services/analytics/useGetAnalyticsLocation'
import productCardClickEvent from '../../utils/analytics/productCardClickEvent'
import { AnalyticsContext } from '../../utils/types/analytics'
import ProductCardButtons from '../Buttons/ProductCardButtons'
import { getCountryAndLocaleStrings } from '../../utils/locales'
import useGetLocalizedProductUrl from './hooks/useGetLocalizedProductUrl'

import styles from '../../styles/ProductCardSimple.module.less'

interface ProductCardSimpleProps {
  product: Product
  onAnalyticsClick?: (event: MouseEvent<HTMLElement>) => void
  analyticsContext?: AnalyticsContext
  align?: 'left' | 'center'
  hasBorder?: boolean
  isGrid?: boolean
}

const ProductCardSimple = (props: ProductCardSimpleProps) => {
  const { product, onAnalyticsClick, analyticsContext, align = 'center', hasBorder = true, isGrid = false } = props

  const { t } = useTranslation('products')
  const { urlT } = useUrls()
  const { final: { reference: finalReferencePrice }, currency, moq } = usePrice(product, 1)
  const { locale: countryAndLocale } = useRouter()
  const { country } = getCountryAndLocaleStrings(countryAndLocale)
  const getLocalizedProductUrl = useGetLocalizedProductUrl()
  const analyticsLocation = useGetAnalyticsLocation('product_card_simple')

  const productName = `${getProductName(product)}${getProductSalesCount(product, t)}`
  const referencePrice = formatPriceNumber(finalReferencePrice)
  const productPricingUnit = getPricePerPieceUnit(product)
  const productUrl = urlT(getPathName(product, t))

  // NOTE: Used for both click and context menu, decouple if the logic branches out
  const handleAnalyticsClick = (event: MouseEvent<HTMLElement>) => {
    // Analytics event
    onAnalyticsClick?.(event)
    productCardClickEvent({
      event,
      context: analyticsContext || analyticsLocation,
      product,
      country,
      getLocalizedProductUrl,
    })
  }

  const referencePriceText = `${referencePrice} ${currency} / ${t(productPricingUnit, { count: 1 })}`
  const moqText = `${t('Minimum')} ${getSmallestBarcodeItemsMoq(product, t, moq)}`
  const imageAltText = getProductImageAltText(product)
  const image = product.media?.length ? (
    <Image
      loader={imageLoader}
      src={getLargePreview(product?.media?.[0])}
      alt={imageAltText}
      height={120}
      width={120}
    />
  ) : (
    <ProductEmptyImage
      height={120}
      width={120}
    />
  )

  return (
    <Link
      href={productUrl}
      onClick={handleAnalyticsClick}
      className={`${styles['product-card-simple-wrapper-link']} ${isGrid ? styles['is-grid-item'] : ''}`}
    >
      <FlexboxGrid
        className={`
          ${styles['product-card-simple']} 
          ${align === 'left' ? styles['align-left'] : ''} 
          ${hasBorder ? '' : styles['hide-border']}
          ${isGrid ? styles['is-grid-item'] : ''}`}
      >
        <FlexboxGrid.Item className={styles['image-container']}>
          {image}
          <ProductCardButtons
            className={styles['product-card-buttons']}
            product={product}
            analyticsContext={analyticsContext || analyticsLocation}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          as="p"
          className={styles['product-name']}
        >
          {productName}
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          as="p"
          className={styles['reference-price']}
        >
          {referencePriceText}
        </FlexboxGrid.Item>

        <FlexboxGrid.Item
          as="p"
          className={styles.moq}
        >
          {moqText}
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Link>
  )
}

export default ProductCardSimple
